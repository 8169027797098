import DPIcon from "@/components/DPIcon/DPIcon";
import { linkUrl } from "@/helpers";
import Image from "next/image";
import { getPackageDevices } from "../helpers/statics";
import LoadingLink from "@/components/LoadingLink/LoadingLink";

export default function PackageUsageInformation({ device_combination, usage_info, device_info }: {
  readonly device_combination?: string
  readonly usage_info?: string
  readonly device_info?: string
}) {
  return (
    <div className="flex flex-col justify-start items-stretch">
      <p className="mb-4">
        Eğitimlerimiz <span className="font-bold">CD/DVD/Kitap formatında değildir. </span>
        İnternet üzerinden online üyelik ile izlenebilen video
        derslerden ve sürekli güncellenen test panelinden
        oluşmaktadır.
      </p>

      {
        (usage_info !== undefined) && (
          <p className="mb-4">
            <DPIcon icon="warning" />&nbsp;
            <span>{ usage_info }</span>
          </p>
        )
      }

      {
        (device_combination !== 'pc') && (
          <>
            <p className="mb-4">
              Mobil uygulamamıza Apple Store, Google Play veya AppGallery üzerinden
              <span className="font-bold"> &quot;doping hafıza&quot;</span> araması yaparak veya
              aşağıdaki linklerden ulaşabilirsiniz.
            </p>
            <p className="mb-4 flex gap-2 flex-wrap">
              <LoadingLink target="_blank" rel="noopener noreferrer" title="Doping Hafıza İOS Uygulamasını İndir" href="https://apps.apple.com/app/id1552739422?l=tr">
                <Image
                  src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT ?? '', '/assets/images/global/appstore-download.svg') ?? ''}
                  alt="App Store'dan İndirin"
                  width={182}
                  height={53}
                  className="me-4 mb-3 lg:mb-0"
                />
              </LoadingLink>
              <LoadingLink target="_blank" title="Doping Hafıza Android Uygulamasını İndir" href="https://play.google.com/store/apps/details?id=com.dopinghafiza.mobile.app&amp;hl=tr">
                <Image
                  src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT ?? '', '/assets/images/global/playstore-download.svg') ?? ''}
                  alt="Play Store'dan İndirin"
                  width={182}
                  height={53}
                  className="me-4 mb-3 lg:mb-0"
                />
              </LoadingLink>
              <LoadingLink target="_blank" title="Doping Hafıza Uygulamasını AppGallery'den İndirin" href="https://appgallery.huawei.com/#/app/C104016959">
                <Image
                  src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT ?? '', '/assets/images/global/app-gallery.svg') ?? ''}
                  alt="AppGallery'den İndirin"
                  width={182}
                  height={53}
                  className="me-4 mb-3 lg:mb-0"
                />
              </LoadingLink>
            </p>
            <p className="mb-5">
              <span className="font-bold">Kurulum Bilgisi: </span>
              Doping Hafıza Online Programı&rsquo;nda
              <span className="font-bold text-lava"> 2 Cihaz kullanım hakkınız </span>
              mevcuttur.
            </p>
          </>
        )
      }
      <h5 className="mb-5 text-lg font-bold">
        { (() => {
          if(device_info !== undefined) {
            return device_info;
          } else if(device_combination === 'pc') {
            return 'Bu paketi yalnızca bilgisayarda kullanabilirsiniz.';
          }
          return "Doping Hafıza'nın aynı anda kurulabileceği cihaz kombinasyonları";
        })() }
      </h5>
      
      {
        (device_combination !== 'pc') && (
          <>
            {
              getPackageDevices(device_combination).map((item) => {
                return (
                  <div key={item.id} className={`flex flex-col items-stretch md:flex-row md:justify-stretch md:items-center gap-3 border-[1px] ${item.active ? 'border-green-RYB' : 'border-lava'} bg-slate-50 mb-4 p-5 rounded-lg`}>
                    <div className="flex justify-between items-center">
                      <div className="flex-grow-0 flex-shrink-0 w-[112px]">
                        <Image
                          src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT ?? '', item.image) ?? ''}
                          alt={item.title}
                          width={99}
                          height={66}
                        />
                      </div>
                      <div className="flex-grow-0 flex-shrink-0 md:hidden">
                        <Image
                          src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT ?? '', item.emoji[item.active ? 0 : 1]) ?? ''}
                          alt={item.title}
                          width={30}
                          height={31}
                        />
                      </div>
                    </div>
                    <div className="flex-grow font-bold">
                      <h5 className={`${item.active ? '' : 'text-slate-400'}`}>{item.title}</h5>
                    </div>
                    <div className="hidden md:flex flex-grow-0 flex-shrink-0">
                      <Image
                        src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT ?? '', item.emoji[item.active ? 0 : 1]) ?? ''}
                        alt={item.title}
                        width={30}
                        height={31}
                      />
                    </div>
                  </div>
                );
              })
            }
            
            <div className="flex flex-col items-stretch md:flex-row md:justify-stretch md:items-center gap-3 border-[1px] border-green-RYB bg-slate-50 mb-4 p-5 rounded-lg">
              <div className="flex justify-between items-center">
                <div className="flex-grow-0 flex-shrink-0 w-[112px]">
                  <Image
                    src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT ?? '', '/assets/images/temp/rootlu-cihaz.png') ?? ''}
                    alt="Desteklenen Cihazlar"
                    width={87}
                    height={58}
                    className="me-[12px]"
                  />
                </div>
                <div className="flex-grow-0 flex-shrink-0 md:hidden">
                  <Image
                    src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT ?? '', '/assets/images/global/emoji/thumbs-up.png') ?? ''}
                    alt="Desteklenen Cihazlar"
                    width={30}
                    height={31}
                  />
                </div>
              </div>
              <div className="flex-grow text-xs text-slate-500">
                <h5 className="font-bold text-sm text-slate-600 mb-2">Desteklenen Cihazlar</h5>
                <p>
                  Üretici firmaların destek vermeye devam ettiği cihaz ve işletim sistemi sürümlerinde
                  <span className="font-bold"> Doping Hafıza Online Programı </span> çalışmaktadır.
                </p>
                <p><span className="font-bold">Doping Hafıza Online</span> programı Microsoft tarafından güncelleme yapılmayan
                  &quot;Windows 7&quot; işletim sisteminde <span className="text-lava">çalışmamaktadır.</span>
                </p>
                <p>
                  <span className="font-bold">Doping Hafıza Online</span> programı cihaz özelliği değiştirilmiş (root ve
                  jailbreak yapılmış) mobil cihazlarda <span className="text-lava">çalışmamaktadır.</span>
                </p>
              </div>
              <div className="hidden md:flex flex-grow-0 flex-shrink-0">
                <Image
                  src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT ?? '', '/assets/images/global/emoji/thumbs-up.png') ?? ''}
                  alt="Desteklenen Cihazlar"
                  width={30}
                  height={31}
                />
              </div>
            </div>
          </>
        )
      }
    </div>
  );
}